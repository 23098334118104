html{
  background-color: #eef2f6;
}
*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.content
{
  height: 80vh;
}
.register-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90vh;
}